.App {
  text-align: center;
}

.App-header {
  background-color: #4d185fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #e6cee6ff;
}

.App-link {
  color: #61dafb;
}

.container {
  position: relative;
  width: 300px;
  height: 300px;
}

svg {
  position: absolute;
  top: 0;
  left: 0;
}

p {
  padding: 10px;
}
